import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import NProgress from 'nprogress'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "GeneralView" */ '@/views/common/layout/LoggedView.vue'
      ),
    children: [
      {
        path: '',
        name: 'HomeRedirect',
        redirect: { name: 'MyAccount' },
      },

      {
        path: 'my',
        name: 'MyArea',
        meta: {
          moduleName: 'Conta',
          routeGroupName: 'My',
          apiScope: 'account',
        },
        component: () =>
          import(
            /* webpackChunkName: "GeneralView" */ '@/views/common/layout/GeneralView.vue'
          ),
        children: [
          {
            path: '',
            name: 'MyAreaRedirect',
            redirect: { name: 'MyAccount' },
          },

          {
            path: 'account/:version?',
            name: 'MyAccount',
            props: (route) => ({
              version: parseInt(route.params.version) || 0,
            }),
            meta: { title: 'Meus dados' },
            component: () =>
              import(
                /* webpackChunkName: "MyAreaAccount" */ '@/views/my/AccountView.vue'
              ),
          },

          {
            path: 'notifications',
            name: 'MyNotifications',
            meta: { title: 'Minhas notificações' },
            component: () =>
              import(
                /* webpackChunkName: "MyAreaAccount" */ '@/views/my/NotificationsView.vue'
              ),
          },

          {
            path: 'users',
            name: 'MyUsers',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MyUsersRedirect',
                redirect: { name: 'MyUsersList' },
              },

              {
                path: 'list',
                name: 'MyUsersList',
                meta: { title: 'Listagem de Usuários' },
                component: () =>
                  import(
                    /* webpackChunkName: "MyUsers" */ '@/views/my/UsersView.vue'
                  ),
              },

              {
                path: 'edit/:userId/:version?',
                props: (route) => ({
                  userId: parseInt(route.params.userId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'MyUsersEdit',
                meta: { title: 'Edição de Usuário' },
                component: () =>
                  import(
                    /* webpackChunkName: "MyUsers" */ '@/views/my/UserView.vue'
                  ),
              },
            ],
          },

          {
            path: 'entities',
            name: 'MyEntities',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MyEntitiesRedirect',
                redirect: { name: 'MyEntitiesList' },
              },

              {
                path: 'list',
                name: 'MyEntitiesList',
                meta: { title: 'Listagem de Empresas' },
                component: () =>
                  import(
                    /* webpackChunkName: "MyEntities" */ '@/views/my/EntitiesView.vue'
                  ),
              },

              {
                path: 'edit/:entityId/:version?',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'MyEntitiesEdit',
                meta: { title: 'Edição de Empresa' },
                component: () =>
                  import(
                    /* webpackChunkName: "MyEntities" */ '@/views/my/EntityView.vue'
                  ),
              },

              {
                path: 'address/:entityId/:addressId',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  addressId: parseInt(route.params.addressId) || 0,
                }),
                name: 'MyEntitiesAddress',
                meta: {
                  title: 'Edição de Endereço',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "MyEntities" */ '@/views/common/EntityAddressView.vue'
                  ),
              },
            ],
          },

          {
            path: 'config',
            meta: { title: 'Minhas Configurações' },
            component: () =>
              import(
                /* webpackChunkName: "MyAreaConfig" */ '@/views/my/ConfigView.vue'
              ),
          },

          {
            path: 'tap/:publicationId',
            props: (route) => ({
              publicationId: parseInt(route.params.publicationId) || 0,
            }),

            name: 'MyTap',
            meta: { title: 'Tap' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/my/TapView.vue'
              ),
          },

          {
            path: 'signed/:signatureId',
            props: (route) => ({
              signatureId: parseInt(route.params.signatureId) || 0,
            }),

            name: 'MySigned',
            meta: { title: 'Signed' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/my/SignedView.vue'
              ),
          },
        ],
      },

      {
        path: 'condor',
        name: 'CondorArea',
        meta: {
          moduleName: 'Condor',
          routeGroupName: 'Condor',
          apiScope: 'condor',
        },
        component: () =>
          import(
            /* webpackChunkName: "CondorView" */ '@/views/common/layout/CondorView.vue'
          ),
        children: [
          {
            path: '',
            name: 'CondorAreaRedirect',
            redirect: { name: 'CondorRfpsListNew' },
          },

          {
            path: 'rfps',
            name: 'CondorRfps',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'CondorRfpsRedirect',
                redirect: { name: 'CondorRfpsListNew' },
              },
              {
                path: 'list-new',
                name: 'CondorRfpsListNew',
                meta: {
                  title: 'Novos Orçamentos',
                  domain: 'new',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorRfps" */ '@/views/condor/RfpsView.vue'
                  ),
              },

              {
                path: 'list-in-progress',
                name: 'CondorRfpsListInProgress',
                meta: {
                  title: 'Orçamentos em andamento',
                  domain: 'in-progress',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorRfps" */ '@/views/condor/RfpsView.vue'
                  ),
              },

              {
                path: 'list-archived',
                name: 'CondorRfpsListArchived',
                meta: {
                  title: 'Orçamentos Arquivados',
                  domain: 'archived',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorRfps" */ '@/views/condor/RfpsView.vue'
                  ),
              },

              {
                path: 'edit/:rfpId',
                props: (route) => ({
                  rfpId: parseInt(route.params.rfpId) || 0,
                }),
                name: 'CondorRfpsEdit',
                meta: { title: 'Edição de Orçamento' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorRfps" */ '@/views/condor/RfpView.vue'
                  ),
              },

              {
                path: 'file/:fileId',
                props: (route) => ({
                  fileId: parseInt(route.params.fileId) || 0,
                }),
                name: 'CondorRfpsFile',
                meta: { title: 'Edição de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorRfps" */ '@/views/common/FileView.vue'
                  ),
              },
            ],
          },

          {
            path: 'products',
            name: 'CondorProducts',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'CondorProductsRedirect',
                redirect: { name: 'CondorProductsList' },
              },

              {
                path: 'list',
                name: 'CondorProductsList',
                meta: { title: 'Listagem global de Produtos' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/condor/ProductsView.vue'
                  ),
              },

              {
                path: 'edit/:productId/:version?',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'CondorProductsEdit',
                meta: { title: 'Edição de Produtos' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/common/ProductView.vue'
                  ),
              },

              {
                path: 'pack/:productId/:packId',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  packId: parseInt(route.params.packId) || 0,
                }),
                name: 'CondorProductPackEdit',
                meta: {
                  title: 'Edição de Embalagem',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/common/ProductPackView.vue'
                  ),
              },

              {
                path: 'volume/:productId/:packId/:volumeId',
                props: (route) => ({
                  packId: parseInt(route.params.packId) || 0,
                  volumeId: parseInt(route.params.volumeId) || 0,
                  productId: parseInt(route.params.productId) || 0,
                }),
                name: 'CondorProductVolumeEdit',
                meta: {
                  title: 'Edição de Volume',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/common/ProductVolumeView.vue'
                  ),
              },

              {
                path: 'audit/:productId',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                }),
                name: 'CondorProductAuditList',
                meta: { title: 'Auditoria de produto' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/condor/ProductAuditView.vue'
                  ),
              },

              {
                path: 'audit-event/:eventId',
                props: (route) => ({
                  eventId: parseInt(route.params.eventId) || 0,
                }),
                name: 'CondorProductAuditEvent',
                meta: { title: 'Auditoria de produto' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorProducts" */ '@/views/condor/ProductEventView.vue'
                  ),
              },
            ],
          },

          {
            path: 'folders',
            name: 'CondorFolders',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'CondorFoldersRedirect',
                redirect: { name: 'CondorFoldersList' },
              },

              {
                path: 'list',
                name: 'CondorFoldersList',
                meta: { title: 'Listagem de Pastas' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorFolders" */ '@/views/condor/FoldersView.vue'
                  ),
              },

              {
                path: 'edit/:folderId/:version?',
                props: (route) => ({
                  folderId: parseInt(route.params.folderId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'CondorFoldersEdit',
                meta: { title: 'Edição de Pastas' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorFolders" */ '@/views/condor/FolderView.vue'
                  ),
              },

              {
                path: 'create/:modus',
                props: (route) => ({
                  modus: route.params.modus,
                }),
                name: 'CondorFoldersCreate',
                meta: { title: 'Criação de Pastas' },
                component: () =>
                  import(
                    /* webpackChunkName: "CondorFolders" */ '@/views/condor/CreateFolderView.vue'
                  ),
              },
            ],
          },
        ],
      },

      {
        path: 'seller',
        name: 'SellerArea',
        meta: {
          moduleName: 'Representante',
          routeGroupName: 'Seller',
          apiScope: 'seller',
        },
        component: () =>
          import(
            /* webpackChunkName: "SellerView" */ '@/views/common/layout/SellerView.vue'
          ),
        children: [
          {
            path: '',
            name: 'SellerAreaRedirect',
            redirect: { name: 'SellerRfpsListMy' },
          },

          {
            path: 'products',
            name: 'SellerProducts',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'SellerProductsRedirect',
                redirect: { name: 'SellerProductsList' },
              },

              {
                path: 'list',
                name: 'SellerProductsList',
                meta: { title: 'Listagem de Produtos' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerProducts" */ '@/views/seller/ProductsView.vue'
                  ),
              },

              {
                path: 'list-products',
                name: 'SellerRfpsListProducts',
                meta: {
                  title: 'Produtos sob orçamento',
                  domain: 'products',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpsView.vue'
                  ),
              },

              {
                path: 'edit/:productId/:version?',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'SellerProductsEdit',
                meta: { title: 'Edição de Produto' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerProducts" */ '@/views/common/ProductView.vue'
                  ),
              },

              {
                path: 'pack/:productId/:packId',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  packId: parseInt(route.params.packId) || 0,
                }),
                name: 'SellerProductPackEdit',
                meta: {
                  title: 'Edição de Embalagem',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerProducts" */ '@/views/common/ProductPackView.vue'
                  ),
              },

              {
                path: 'volume/:productId/:packId/:volumeId',
                props: (route) => ({
                  packId: parseInt(route.params.packId) || 0,
                  volumeId: parseInt(route.params.volumeId) || 0,
                  productId: parseInt(route.params.productId) || 0,
                }),
                name: 'SellerProductVolumeEdit',
                meta: {
                  title: 'Edição de Volume',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerProducts" */ '@/views/common/ProductVolumeView.vue'
                  ),
              },
            ],
          },

          {
            path: 'rfps',
            name: 'SellerRfps',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'SellerRfpsRedirect',
                redirect: { name: 'SellerRfpsListMy' },
              },

              {
                path: 'list-my',
                name: 'SellerRfpsListMy',
                meta: {
                  title: 'Meus Orçamentos',
                  domain: 'my',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpsView.vue'
                  ),
              },

              {
                path: 'list-new',
                name: 'SellerRfpsListNew',
                meta: {
                  title: 'Novas Solicitações',
                  domain: 'new',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpsView.vue'
                  ),
              },

              {
                path: 'list-archived',
                name: 'SellerRfpsListArchived',
                meta: {
                  title: 'Orçamentos Arquivados',
                  domain: 'archived',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpsView.vue'
                  ),
              },

              {
                path: 'edit/:rfpId',
                props: (route) => ({
                  rfpId: parseInt(route.params.rfpId) || 0,
                }),
                name: 'SellerRfpsEdit',
                meta: { title: 'Edição de Orçamento' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpView.vue'
                  ),
              },

              {
                path: 'proceed/:rfpId',
                props: (route) => ({
                  rfpId: parseInt(route.params.rfpId) || 0,
                }),
                name: 'SellerRfpsProceed',
                meta: { title: 'Prosseguimento de Orçamento' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/seller/RfpProceedView.vue'
                  ),
              },

              {
                path: 'file/:fileId',
                props: (route) => ({
                  fileId: parseInt(route.params.fileId) || 0,
                }),
                name: 'SellerRfpsFile',
                meta: { title: 'Edição de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerRfps" */ '@/views/common/FileView.vue'
                  ),
              },
            ],
          },

          {
            path: 'instances',
            name: 'SellerInstances',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'SellerInstancesRedirect',
                redirect: { name: 'SellerInstancesList' },
              },

              {
                path: 'list',
                name: 'SellerInstancesList',
                meta: { title: 'Listagem de Grupos' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerInstances" */ '@/views/seller/InstancesView.vue'
                  ),
              },

              {
                path: 'edit/:instanceId/:version?',
                props: (route) => ({
                  instanceId: parseInt(route.params.instanceId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'SellerInstancesEdit',
                meta: { title: 'Edição de Grupo' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerInstances" */ '@/views/seller/InstanceView.vue'
                  ),
              },
            ],
          },

          {
            path: 'entities',
            name: 'SellerEntities',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'SellerEntitiesRedirect',
                redirect: { name: 'SellerEntitiesList' },
              },

              {
                path: 'list',
                name: 'SellerEntitiesList',
                meta: { title: 'Listagem de Empresas' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerEntities" */ '@/views/seller/EntitiesView.vue'
                  ),
              },

              {
                path: 'edit/:entityId/:version?',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'SellerEntitiesEdit',
                meta: { title: 'Edição de Empresa' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerEntities" */ '@/views/seller/EntityView.vue'
                  ),
              },

              {
                path: 'address/:entityId/:addressId',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  addressId: parseInt(route.params.addressId) || 0,
                }),
                name: 'SellerEntitiesAddress',
                meta: {
                  title: 'Edição de Endereço',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerEntities" */ '@/views/common/EntityAddressView.vue'
                  ),
              },
            ],
          },

          {
            path: 'grants',
            name: 'SellerGrants',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'SellerGrantsRedirect',
                redirect: { name: 'SellerGrantsList' },
              },

              {
                path: 'list',
                name: 'SellerGrantsList',
                meta: { title: 'Listagem de Outorgas' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerGrants" */ '@/views/seller/GrantsView.vue'
                  ),
              },

              {
                path: 'search',
                name: 'SellerGrantsSearch',
                meta: { title: 'Busca de Empresas' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerGrants" */ '@/views/seller/GrantSearchView.vue'
                  ),
              },

              {
                path: 'request',
                name: 'SellerGrantsRequest',
                meta: { title: 'Nova outorga' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerGrants" */ '@/views/seller/GrantRequestView.vue'
                  ),
              },

              {
                path: 'edit/:grantId',
                props: (route) => ({
                  grantId: parseInt(route.params.grantId) || 0,
                }),
                name: 'SellerGrantsEdit',
                meta: { title: 'Outorga' },
                component: () =>
                  import(
                    /* webpackChunkName: "SellerGrants" */ '@/views/seller/GrantView.vue'
                  ),
              },
            ],
          },
        ],
      },

      {
        path: 'buyer',
        name: 'BuyerArea',
        meta: {
          moduleName: 'Comprador',
          routeGroupName: 'Buyer',
          apiScope: 'buyer',
        },
        component: () =>
          import(
            /* webpackChunkName: "BuyerView" */ '@/views/common/layout/BuyerView.vue'
          ),
        children: [
          {
            path: '',
            name: 'BuyerAreaRedirect',
            redirect: { name: 'BuyerRfp' },
          },

          // {
          //   path: 'address/:entityId/:addressId',
          //   props: (route) => ({
          //     entityId: parseInt(route.params.entityId) || 0,
          //     addressId: parseInt(route.params.addressId) || 0,
          //   }),
          //   name: 'BuyerEntitiesAddress',
          //   meta: {
          //     title: 'Edição de Endereço',
          //   },
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "BuyerEntities" */ '@/views/common/EntityAddressView.vue'
          //     ),
          // },

          {
            path: 'tap/:publicationId/:origin/:id',
            props: (route) => ({
              publicationId: route.params.publicationId || '',
              origin: route.params.origin || '',
              id: parseInt(route.params.id) || 0,
            }),

            name: 'BuyerTap',
            meta: { title: 'Tap' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/buyer/TapView.vue'
              ),
          },

          {
            path: 'rfp',
            name: 'BuyerRfp',
            meta: { title: 'Checkout' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/buyer/CurrentRfpView.vue'
              ),
          },

          {
            path: 'rfp-list',
            name: 'BuyerRfpList',
            meta: { title: 'Meus Carrinhos' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/buyer/CurrentRfpListView.vue'
              ),
          },

          {
            path: 'rfp-success',
            name: 'BuyerRfpSuccess',
            meta: { title: 'Orçamento' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaRfp" */ '@/views/buyer/CurrentRfpSuccessView.vue'
              ),
          },

          {
            path: 'rfps',
            name: 'BuyerRfps',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'BuyerRfpsRedirect',
                redirect: { name: 'BuyerRfpsList' },
              },

              {
                path: 'list',
                name: 'BuyerRfpsList',
                meta: {
                  title: 'Meus Orçamentos',
                  domain: 'my',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerRfps" */ '@/views/buyer/RfpsView.vue'
                  ),
              },

              {
                path: 'edit/:rfpId',
                props: (route) => ({
                  rfpId: parseInt(route.params.rfpId) || 0,
                }),
                name: 'BuyerRfpsEdit',
                meta: { title: 'Edição de Orçamento' },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerRfps" */ '@/views/buyer/RfpView.vue'
                  ),
              },

              {
                path: 'file/:fileId',
                props: (route) => ({
                  fileId: parseInt(route.params.fileId) || 0,
                }),
                name: 'BuyerRfpsFile',
                meta: { title: 'Edição de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerRfps" */ '@/views/common/FileView.vue'
                  ),
              },
            ],
          },

          {
            path: 'grants',
            name: 'BuyerGrants',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'BuyerGrantsRedirect',
                redirect: { name: 'BuyerGrantsList' },
              },

              {
                path: 'list',
                name: 'BuyerGrantsList',
                meta: { title: 'Listagem de Outorgas' },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerGrants" */ '@/views/buyer/GrantsView.vue'
                  ),
              },
              {
                path: 'edit/:grantId',
                props: (route) => ({
                  grantId: parseInt(route.params.grantId) || 0,
                }),
                name: 'BuyerGrantsEdit',
                meta: { title: 'Outorga' },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerGrants" */ '@/views/buyer/GrantView.vue'
                  ),
              },
            ],
          },

          {
            path: 'bookmarks',
            meta: { title: 'Meus Favoritos' },
            component: () =>
              import(
                /* webpackChunkName: "BuyerAreaBookmarks" */ '@/views/buyer/BookmarksView.vue'
              ),
          },
        ],
      },

      {
        path: 'moderator',
        name: 'ModeratorArea',
        meta: {
          moduleName: 'Moderação',
          routeGroupName: 'Moderator',
          apiScope: 'moderator',
        },
        component: () =>
          import(
            /* webpackChunkName: "ModeratorView" */ '@/views/common/layout/ModeratorView.vue'
          ),
        children: [
          {
            path: '',
            name: 'ModeratorAreaRedirect',
            redirect: { name: 'ModeratorInstancesList' },
          },

          {
            path: 'instances',
            name: 'ModeratorInstances',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ModeratorInstancesRedirect',
                redirect: { name: 'ModeratorInstancesList' },
              },

              {
                path: 'list',
                name: 'ModeratorInstancesList',
                meta: { title: 'Listagem de Grupos' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorUsers" */ '@/views/moderator/InstancesView.vue'
                  ),
              },

              {
                path: 'edit/:instanceId/:version?',
                props: (route) => ({
                  instanceId: parseInt(route.params.instanceId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'ModeratorInstancesEdit',
                meta: { title: 'Edição de Grupo' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorUsers" */ '@/views/moderator/InstanceView.vue'
                  ),
              },
            ],
          },

          {
            path: 'entities',
            name: 'ModeratorEntities',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ModeratorEntitiesRedirect',
                redirect: { name: 'ModeratorEntitiesList' },
              },

              {
                path: 'list',
                name: 'ModeratorEntitiesList',
                meta: { title: 'Listagem de Empresas' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorEntities" */ '@/views/moderator/EntitiesView.vue'
                  ),
              },

              {
                path: 'edit/:entityId/:version?',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'ModeratorEntitiesEdit',
                meta: { title: 'Edição de Empresa' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorEntities" */ '@/views/moderator/EntityView.vue'
                  ),
              },
            ],
          },

          {
            path: 'product-images',
            name: 'ModeratorProductImages',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ModeratorProductImagesRedirect',
                redirect: { name: 'ModeratorProductImagesList' },
              },

              {
                path: 'list',
                name: 'ModeratorProductImagesList',
                meta: { title: 'Listagem de Produtos' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorProducts" */ '@/views/moderator/ProductImagesView.vue'
                  ),
              },

              {
                path: 'edit/:productId/:version?',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'ModeratorProductImagesEdit',
                meta: { title: 'Edição de Produto' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorEntities" */ '@/views/moderator/ProductImageView.vue'
                  ),
              },
            ],
          },

          {
            path: 'products',
            name: 'ModeratorProducts',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ModeratorProducsRedirect',
                redirect: { name: 'ModeratorProductsList' },
              },

              {
                path: 'list',
                name: 'ModeratorProductsList',
                meta: { title: 'Listagem de Produtos' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorProducts" */ '@/views/moderator/ProductsView.vue'
                  ),
              },

              {
                path: 'edit/:productId/:version?',
                props: (route) => ({
                  productId: parseInt(route.params.productId) || 0,
                  version: parseInt(route.params.version) || 0,
                }),
                name: 'ModeratorProductsEdit',
                meta: { title: 'Edição de Produto' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorEntities" */ '@/views/moderator/ProductView.vue'
                  ),
              },
            ],
          },
          {
            path: 'grants',
            name: 'ModeratorGrants',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'ModeratorGrantsRedirect',
                redirect: { name: 'ModeratorGrantsList' },
              },

              {
                path: 'list',
                name: 'ModeratorGrantsList',
                meta: { title: 'Listagem de Outorgas' },
                component: () =>
                  import(
                    /* webpackChunkName: "BuyerGrants" */ '@/views/moderator/GrantsView.vue'
                  ),
              },
              {
                path: 'edit/:grantId',
                props: (route) => ({
                  grantId: parseInt(route.params.grantId) || 0,
                }),
                name: 'ModeratorGrantsEdit',
                meta: { title: 'Outorga' },
                component: () =>
                  import(
                    /* webpackChunkName: "ModeratorGrants" */ '@/views/moderator/GrantView.vue'
                  ),
              },
            ],
          },
        ],
      },

      {
        path: 'master',
        name: 'MasterArea',
        meta: {
          moduleName: 'Sistema',
          routeGroupName: 'Master',
          apiScope: 'global',
        },
        component: () =>
          import(
            /* webpackChunkName: "MasterView" */ '@/views/common/layout/MasterView.vue'
          ),
        children: [
          {
            path: '',
            name: 'MasterAreaRedirect',
            redirect: { name: 'MasterInstancesList' },
          },

          {
            path: 'whatsapp',
            name: 'MessageWhatsApp',
            component: () =>
              import(
                /* webpackChunkName: "WhatsAppView" */ '@/views/master/WhatsAppView.vue'
              ),
          },

          {
            path: 'roots',
            name: 'MasterUacRoots',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterUacRootsRedirect',
                redirect: { name: 'MasterUacRootsList' },
              },

              {
                path: 'list',
                name: 'MasterUacRootsList',
                meta: { title: 'Listagem de Roots' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/RootsView.vue'
                  ),
              },

              {
                path: 'edit/:rootId',
                props: (route) => ({
                  rootId: parseInt(route.params.rootId) || 0,
                }),
                name: 'MasterUacRootsEdit',
                meta: { title: 'Edição de Root' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/RootView.vue'
                  ),
              },
            ],
          },
          {
            path: 'root-groups',
            name: 'MasterUacRootGroups',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterUacRootGroupsRedirect',
                redirect: { name: 'MasterUacRootGroupsList' },
              },

              {
                path: 'list',
                name: 'MasterUacRootGroupsList',
                meta: { title: 'Listagem de Grupos de Roots' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/RootGroupsView.vue'
                  ),
              },

              {
                path: 'edit/:groupId',
                props: (route) => ({
                  groupId: parseInt(route.params.groupId) || 0,
                }),
                name: 'MasterUacRootGroupsEdit',
                meta: { title: 'Edição de Grupo de Roots' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/RootGroupView.vue'
                  ),
              },
            ],
          },
          {
            path: 'profiles',
            name: 'MasterUacProfiles',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterUacProfilesRedirect',
                redirect: { name: 'MasterUacProfilesList' },
              },

              {
                path: 'list',
                name: 'MasterUacProfilesList',
                meta: { title: 'Listagem de Grupos de Perfis' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/ProfilesView.vue'
                  ),
              },

              {
                path: 'edit/:profileId',
                props: (route) => ({
                  profileId: parseInt(route.params.profileId) || 0,
                }),
                name: 'MasterUacProfilesEdit',
                meta: { title: 'Edição de Grupo de Perfis' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUac" */ '@/views/master/ProfileView.vue'
                  ),
              },
            ],
          },
          {
            path: 'instances',
            name: 'MasterInstances',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterInstancesRedirect',
                redirect: { name: 'MasterInstancesList' },
              },

              {
                path: 'list',
                name: 'MasterInstancesList',
                meta: { title: 'Listagem de Grupos' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterInstances" */ '@/views/master/InstancesView.vue'
                  ),
              },

              {
                path: 'edit/:instanceId/:leadId?',
                props: (route) => ({
                  instanceId: parseInt(route.params.instanceId) || 0,
                  leadId: parseInt(route.params.leadId) || 0,
                }),
                name: 'MasterInstancesEdit',
                meta: { title: 'Edição de Grupo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterInstances" */ '@/views/master/InstanceView.vue'
                  ),
              },
            ],
          },

          {
            path: 'entities',
            name: 'MasterEntities',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterEntitiesRedirect',
                redirect: { name: 'MasterEntitiesList' },
              },

              {
                path: 'list',
                name: 'MasterEntitiesList',
                meta: { title: 'Listagem de Empresas' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterEntities" */ '@/views/master/EntitiesView.vue'
                  ),
              },

              {
                path: 'edit/:entityId',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                }),
                name: 'MasterEntitiesEdit',
                meta: { title: 'Edição de Empresa' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterEntities" */ '@/views/master/EntityView.vue'
                  ),
              },

              {
                path: 'address/:entityId/:addressId',
                props: (route) => ({
                  entityId: parseInt(route.params.entityId) || 0,
                  addressId: parseInt(route.params.addressId) || 0,
                }),
                name: 'MasterEntitiesAddress',
                meta: {
                  title: 'Edição de Endereço',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterEntities" */ '@/views/common/EntityAddressView.vue'
                  ),
              },
            ],
          },

          {
            path: 'users',
            name: 'MasterUsers',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterUsersRedirect',
                redirect: { name: 'MasterUsersList' },
              },

              {
                path: 'list',
                name: 'MasterUsersList',
                meta: { title: 'Listagem de Usuarios' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUsers" */ '@/views/master/UsersView.vue'
                  ),
              },

              {
                path: 'edit/:userId',
                props: (route) => ({
                  userId: parseInt(route.params.userId) || 0,
                }),
                name: 'MasterUsersEdit',
                meta: { title: 'Edição de Usuário' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterUsers" */ '@/views/master/UserView.vue'
                  ),
              },
            ],
          },

          {
            path: 'subscriptions',
            name: 'MasterSubscriptions',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterSubscriptionsRedirect',
                redirect: { name: 'MasterSubscriptionsList' },
              },

              {
                path: 'list-new',
                name: 'MasterSubscriptionsListNew',
                meta: { title: 'Listagem de Assinaturas', domain: 'new' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterSubscriptions" */ '@/views/master/SubscriptionsView.vue'
                  ),
              },

              {
                path: 'list-archived',
                name: 'MasterSubscriptionsListArchived',
                meta: { title: 'Listagem de Assinaturas', domain: 'archived' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterSubscriptions" */ '@/views/master/SubscriptionsView.vue'
                  ),
              },

              {
                path: 'edit/:subscriptionId',
                props: (route) => ({
                  subscriptionId: parseInt(route.params.subscriptionId) || 0,
                }),
                name: 'MasterSubscriptionsEdit',
                meta: { title: 'Edição de Assinatura' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterSubscriptions" */ '@/views/master/SubscriptionView.vue'
                  ),
              },
            ],
          },

          {
            path: 'newsletter',
            name: 'MasterNewsletter',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterNewsletterRedirect',
                redirect: { name: 'MasterNewsletterList' },
              },

              {
                path: 'list',
                name: 'MasterNewsletterList',
                meta: { title: 'Listagem de Assinaturas' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterNewsletter" */ '@/views/master/NewsletterView.vue'
                  ),
              },
            ],
          },

          {
            path: 'testimonials',
            name: 'MasterTestimonials',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterTestimonialsRedirect',
                redirect: { name: 'MasterTestimonialsList' },
              },

              {
                path: 'list',
                name: 'MasterTestimonialsList',
                meta: { title: 'Listagem de Testemunhos' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTestimonial" */ '@/views/master/TestimonialsView.vue'
                  ),
              },

              {
                path: 'edit/:testimonialId',
                props: (route) => ({
                  testimonialId: parseInt(route.params.testimonialId) || 0,
                }),
                name: 'MasterTestimonialsEdit',
                meta: { title: 'Edição de Testemunho' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTestimonial" */ '@/views/master/TestimonialView.vue'
                  ),
              },
            ],
          },

          {
            path: 'brands',
            name: 'MasterBrands',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterBrandsRedirect',
                redirect: { name: 'MasterBrandsList' },
              },

              {
                path: 'list',
                name: 'MasterBrandsList',
                meta: { title: 'Listagem de Marcas' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterBrand" */ '@/views/master/BrandsView.vue'
                  ),
              },

              {
                path: 'edit/:brandId',
                props: (route) => ({
                  brandId: parseInt(route.params.brandId) || 0,
                }),
                name: 'MasterBrandsEdit',
                meta: { title: 'Edição de Marca' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterBrand" */ '@/views/master/BrandView.vue'
                  ),
              },
            ],
          },

          {
            path: 'file-types',
            name: 'MasterFileType',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterFileTypeRedirect',
                redirect: { name: 'MasterFileTypeList' },
              },

              {
                path: 'list',
                name: 'MasterFileTypeList',
                meta: { title: 'Listagem de Tipos de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileType" */ '@/views/master/FileTypesView.vue'
                  ),
              },

              {
                path: 'edit/:typeId',
                props: (route) => ({
                  typeId: parseInt(route.params.typeId) || 0,
                }),
                name: 'MasterFileTypeEdit',
                meta: { title: 'Edição de Tipo de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileType" */ '@/views/master/FileTypeView.vue'
                  ),
              },
            ],
          },

          {
            path: 'file-groups',
            name: 'MasterFileGroups',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterFileGroupsRedirect',
                redirect: { name: 'MasterFileGroupsList' },
              },

              {
                path: 'list',
                name: 'MasterFileGroupsList',
                meta: { title: 'Listagem de Grupos de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileGroups" */ '@/views/master/FileGroupsView.vue'
                  ),
              },

              {
                path: 'edit/:groupId',
                props: (route) => ({
                  groupId: parseInt(route.params.groupId) || 0,
                }),
                name: 'MasterFileGroupsEdit',
                meta: { title: 'Edição de Grupo de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileGroups" */ '@/views/master/FileGroupView.vue'
                  ),
              },
            ],
          },

          {
            path: 'file-tags',
            name: 'MasterFileTags',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterFileTagsRedirect',
                redirect: { name: 'MasterFileTagsList' },
              },

              {
                path: 'list',
                name: 'MasterFileTagsList',
                meta: { title: 'Listagem de Tags de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileTags" */ '@/views/master/FileTagsView.vue'
                  ),
              },

              {
                path: 'edit/:tagId',
                props: (route) => ({
                  tagId: parseInt(route.params.tagId) || 0,
                }),
                name: 'MasterFileTagsEdit',
                meta: { title: 'Edição de Tag de Arquivo' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterFileTags" */ '@/views/master/FileTagView.vue'
                  ),
              },
            ],
          },

          {
            path: 'currencies',
            name: 'MasterCurrencies',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterCurrenciesRedirect',
                redirect: { name: 'MasterCurrenciesList' },
              },

              {
                path: 'list',
                name: 'MasterCurrenciesList',
                meta: { title: 'Listagem de Moedas' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterCurrencies" */ '@/views/master/CurrenciesView.vue'
                  ),
              },

              {
                path: 'edit/:currencyId',
                props: (route) => ({
                  currencyId: parseInt(route.params.currencyId) || 0,
                }),
                name: 'MasterCurrenciesEdit',
                meta: { title: 'Edição de Moeda' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterCurrencies" */ '@/views/master/CurrencyView.vue'
                  ),
              },
            ],
          },

          {
            path: 'categories',
            name: 'MasterCategories',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterCategoriesRedirect',
                redirect: { name: 'MasterCategoriesList' },
              },

              {
                path: 'list',
                name: 'MasterCategoriesList',
                meta: { title: 'Listagem de Categorias' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterCategories" */ '@/views/master/CategoriesView.vue'
                  ),
              },

              {
                path: 'edit/:categoryId/:parentId?',
                props: (route) => ({
                  categoryId: parseInt(route.params.categoryId) || 0,
                  parentId: parseInt(route.params.parentId) || 0,
                }),
                name: 'MasterCategoriesEdit',
                meta: { title: 'Edição de Categoria' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterCategories" */ '@/views/master/CategoryView.vue'
                  ),
              },
            ],
          },

          {
            path: 'tap',
            name: 'MasterTermsAndPolices',
            component: () =>
              import(
                /* webpackChunkName: "ByPassLayout" */ '@/views/common/layout/BypassView.vue'
              ),

            children: [
              {
                path: '',
                name: 'MasterTermsAndPolicesRedirect',
                //redirect: { name: 'MasterTermsAndPolicesListVersions' },
                redirect: { name: 'MasterTermsAndPolicesListDoc' },
              },

              {
                path: 'list-docs',
                name: 'MasterTermsAndPolicesListDoc',
                meta: { title: 'Listagem de Documentos' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTermsAndPolices" */ '@/views/master/TapDocsView.vue'
                  ),
              },

              {
                path: 'edit-docs/:documentId',
                props: (route) => ({
                  documentId: parseInt(route.params.documentId) || 0,
                }),
                name: 'MasterTermsAndPolicesEditDoc',
                meta: { title: 'Edição de Documento' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTermsAndPolices" */ '@/views/master/TapDocView.vue'
                  ),
              },
              {
                path: 'edit-versions/:documentId/:versionId',
                props: (route) => ({
                  documentId: parseInt(route.params.documentId) || 0,
                  versionId: parseInt(route.params.versionId) || 0,
                }),
                name: 'MasterTermsAndPolicesEditVersions',
                meta: { title: 'Edição de Versão' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTermsAndPolices" */ '@/views/master/TapVersionView.vue'
                  ),
              },
              {
                path: 'edit-publications/:documentId/:publicationId',
                props: (route) => ({
                  documentId: parseInt(route.params.documentId) || 0,
                  publicationId: parseInt(route.params.publicationId) || 0,
                }),
                name: 'MasterTermsAndPolicesEditPublications',
                meta: { title: 'Edição de Publicação' },
                component: () =>
                  import(
                    /* webpackChunkName: "MasterTermsAndPolices" */ '@/views/master/TapPublicationView.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/common/layout/PublicView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
  //   if (to.matched.some((record) => record.meta.authorizationRequired)) {
  //     if (store.getters['session/getAuthStatus']) {
  //       next()
  //       return
  //     }
  //     console.log('/users/login')
  //     //next('/users/login')
  //   } else {
  //     next()
  //   }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
