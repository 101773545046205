<template>
  <router-view />
  <g-dialog-root />
</template>

<script>
import { GDialogRoot } from 'gitart-vue-dialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    GDialogRoot,
  },
  computed: {
    ...mapGetters({
      getScrollState: 'screen/getScrollState',
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },

  async created() {
    await this.recoverSession()
    await this.loadPublications()
    await this.loadSigned()
  },

  methods: {
    ...mapActions({
      recoverSession: 'session/recoverSession',
      loadPublications: 'privatePublisher/loadPublications',
      loadSigned: 'privatePublisher/loadSigned',
      setScrollState: 'screen/setScrollState',
      setClientSize: 'screen/setClientSize',
    }),

    handleScroll(e) {
      let pos = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      if (pos < 50 && this.getScrollState) {
        this.setScrollState(false)
      }

      if (pos >= 50 && !this.getScrollState) {
        this.setScrollState(true)
      }
    },

    handleResize() {
      this.setClientSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      })
    },
  },
}
</script>

<style></style>
