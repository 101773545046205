import { apiGetData } from '@/services/ApiService.js'

const state = () => ({
  reasons: [],
  flagDenyBtn: false,
  apiScope: '-',
  rfpStatus: '-',
  rfpFlagArchived: 0,
  rfpFlagSignedByBuyer: 0,

  visibility: false,
  fileControl: false,
  condorPriceControl: false,
  reasonControl: false,
  obsControl: false,
  initBtn: false,
  sendBtn: false,
  archiveBtn: false,
  approveBtn: false,
  signBtn: false,
  draftBtn: false,
  denyBtn: false,
  negotiateBtn: false,
  cancellationRequestBtn: false,
  cancellationApproveBtn: false,
  cancellationDenyBtn: false,
  cancelNegotiationBtn: false,
  purgeBtn: false,
  cancelPurgeBtn: false,

  isLoading: false,
})

const actions = {
  /* Load Data */

  loadReasons({ commit, state }) {
    state.isLoading = true
    apiGetData('store/rfps-actions/list-reasons')
      .then((response) => {
        commit('LOAD_REASONS', response)
      })
      .finally(() => {
        state.isLoading = false
      })
  },

  /* Config */

  configInit({ commit }, data) {
    commit('CONFIG_INIT', data)
  },

  /* UI */

  updateUI({ commit, rootGetters }, data) {
    commit('UPDATE_UI', data)
    commit('UPDATE_SCREEN_CONFIG', rootGetters)
  },
  denyAction({ commit }) {
    commit('DENY_ACTION')
  },
  cancelAction({ commit }) {
    commit('CANCEL_ACTION')
  },
}

const mutations = {
  /* Load Data */

  LOAD_REASONS(state, reasons) {
    state.reasons = reasons
    state.reasons.push({
      id: 0,
      name: 'Para poder recusar, escolha um motivo...',
    })
  },

  /* Config */

  CONFIG_INIT(state, data) {
    state.apiScope = data.apiScope
  },

  /* UI */

  UPDATE_UI(state, data) {
    state.rfpStatus = data.rfpStatus
    state.rfpFlagArchived = data.rfpFlagArchived
    state.rfpFlagSignedByBuyer = data.rfpFlagSignedByBuyer
  },

  DENY_ACTION(state) {
    state.flagDenyBtn = true
  },

  CANCEL_ACTION(state) {
    state.flagDenyBtn = false
  },

  UPDATE_SCREEN_CONFIG(state, rootGetters) {
    // Initial State
    state.initBtn = false
    state.sendBtn = false
    state.archiveBtn = false
    state.approveBtn = false
    state.signBtn = false
    state.draftBtn = false
    state.denyBtn = false
    state.negotiateBtn = false
    state.cancellationRequestBtn = false
    state.cancellationApproveBtn = false
    state.cancellationDenyBtn = false
    state.cancelNegotiationBtn = false
    state.purgeBtn = false
    state.cancelPurgeBtn = false
    state.visibility = false
    state.fileControl = false
    state.condorPriceControl = false
    state.reasonControl = false
    state.obsControl = false
    state.draftBtn = true
    switch (state.rfpStatus) {
      case 'RFP_CREATED':
      case 'RFP_SENT':
      case 'QUOTATION_CANCELLATION_REQUEST_REJECTED':
      case 'QUOTATION_RESUBMIT':
      case 'QUOTATION_SENT':
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:delete')
        ) {
          state.obsControl = true
          state.purgeBtn = true
          state.visibility = true
        }
        break
      case 'QUOTATION_PURGE_REQUESTED':
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:delete')
        ) {
          state.cancelPurgeBtn = true
          state.visibility = true
        }
        break
      case 'QUOTATION_REJECTED':
      case 'QUOTATION_CANCELED_AFTER_APPROVED':
        if (state.apiScope == 'condor') {
          if (rootGetters['session/checkRoot']('store:rfps:edit:condor')) {
            state.obsControl = true
            state.archiveBtn = true
            state.visibility = true
          }

          if (rootGetters['session/checkRoot']('store:rfps:edit:delete')) {
            state.obsControl = true
            state.visibility = true
            state.purgeBtn = true
          }
        }

        break
      case 'QUOTATION_CANCELLATION_REQUEST':
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:condor')
        ) {
          state.cancellationApproveBtn = true
          state.cancellationDenyBtn = true
          state.obsControl = true
          state.visibility = true
          if (rootGetters['session/checkRoot']('store:rfps:edit:delete')) {
            state.purgeBtn = true
          }
        }

        break

      case 'QUOTATION_APPROVED':
        state.visibility = true
        //state.draftBtn = state.rfpFlagSignedByBuyer == 1
        if (
          state.apiScope == 'seller' &&
          rootGetters['session/checkRoot']('store:rfps:edit:seller')
        ) {
          state.obsControl = true
          state.cancellationRequestBtn = true
        }
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:delete')
        ) {
          state.archiveBtn = true
          state.purgeBtn = true
          state.obsControl = true
        }
        if (
          state.apiScope == 'buyer' &&
          rootGetters['session/checkRoot']('store:rfps:sign:buyer')
        ) {
          state.signBtn = state.rfpFlagSignedByBuyer == 0
        }
        break

      case 'RFP_RECEIVED':
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:condor')
        ) {
          state.obsControl = true
          state.visibility = true
          state.initBtn = true
          if (rootGetters['session/checkRoot']('store:rfps:edit:delete')) {
            state.purgeBtn = true
          }
        }
        break
      case 'QUOTATION_IN_PROGRESS':
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:condor')
        ) {
          state.obsControl = true
          state.condorPriceControl = true
          state.visibility = true
          state.fileControl = true
          state.sendBtn = true
          if (rootGetters['session/checkRoot']('store:rfps:edit:delete')) {
            state.purgeBtn = true
          }
        }
        break

      case 'QUOTATION_UNDER_NEGOTIATION':
        if (
          state.apiScope == 'seller' &&
          rootGetters['session/checkRoot']('store:rfps:edit:seller')
        ) {
          state.obsControl = false
          state.visibility = true
          state.cancelNegotiationBtn = true
        }
        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:delete')
        ) {
          state.purgeBtn = true
          state.obsControl = true
          state.visibility = true
        }
        break

      case 'QUOTATION_RECEIVED':
        if (
          state.apiScope == 'seller' &&
          rootGetters['session/checkRoot']('store:rfps:edit:seller')
        ) {
          this.dispatch('rfpsStates/loadReasons')
          state.obsControl = true
          state.visibility = true
          state.approveBtn = true
          state.denyBtn = true
          state.negotiateBtn = true
          state.reasonControl = true
        }

        // if (
        //   state.apiScope == 'buyer' &&
        //   rootGetters['session/checkRoot']('store:rfps:edit:buyer')
        // ) {
        //   this.dispatch('rfpsStates/loadReasons')
        //   state.obsControl = true
        //   state.visibility = true
        //   state.approveBtn = true
        //   state.denyBtn = true
        //   state.reasonControl = true
        // }

        if (
          state.apiScope == 'condor' &&
          rootGetters['session/checkRoot']('store:rfps:edit:delete')
        ) {
          state.purgeBtn = true
          state.obsControl = true
          state.visibility = true
        }
        break
    }

    if (state.rfpFlagArchived) {
      state.visibility = false
    }
  },
}

const getters = {
  getReasons: (state) => {
    return state.reasons
  },

  getFlagDenyBtn: (state) => {
    return state.flagDenyBtn
  },

  getVisibility: (state) => {
    return state.visibility
  },

  getFileControl: (state) => {
    return state.fileControl
  },

  getReasonControl: (state) => {
    return state.reasonControl
  },

  getObsControl: (state) => {
    return state.obsControl
  },

  getCondorPriceControl: (state) => {
    return state.condorPriceControl
  },

  getInitBtn: (state) => {
    return state.initBtn
  },

  getPurgeBtn: (state) => {
    return state.purgeBtn
  },

  getSendBtn: (state) => {
    return state.sendBtn
  },

  getArchiveBtn: (state) => {
    return state.archiveBtn
  },

  getApproveBtn: (state) => {
    return state.approveBtn
  },

  getSignBtn: (state) => {
    return state.signBtn
  },

  getDraftBtn: (state) => {
    return state.draftBtn
  },

  getDenyBtn: (state) => {
    return state.denyBtn
  },

  getNegotiateBtn: (state) => {
    return state.negotiateBtn
  },

  getCancellationRequestBtn: (state) => {
    return state.cancellationRequestBtn
  },

  getCancellationApproveBtn: (state) => {
    return state.cancellationApproveBtn
  },

  getCancellationDenyBtn: (state) => {
    return state.cancellationDenyBtn
  },

  getCancelPurgeBtn: (state) => {
    return state.cancelPurgeBtn
  },

  getCancelNegotiationBtn: (state) => {
    return state.cancelNegotiationBtn
  },

  getState: (state) => {
    return state
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
