import { apiGetData, apiPostData } from '@/services/ApiService.js'

const state = () => ({
  rfp: [],
  rfpChecked: [],
})

const actions = {
  loadRfp({ commit }) {
    apiGetData(`contents/publisher/list/ADM_RFP`).then((response) => {
      commit('LOAD_RFP', response)
      commit('CLEAR_RFP_DOC')
    })
  },
  rfpCheck({ commit }, event) {
    commit('CHECK_RFP_DOC', {
      publication_id: parseInt(event.srcElement.alt),
      status: event.srcElement.checked,
    })
  },
}

const mutations = {
  LOAD_RFP(state, data) {
    state.rfp = data ?? []
  },

  CLEAR_RFP_DOC(state) {
    state.rfpChecked = []
  },

  CHECK_RFP_DOC(state, data) {
    let idx = state.rfpChecked.indexOf(data.publication_id)
    if (data.status) {
      if (idx == -1) {
        state.rfpChecked.push(data.publication_id)
      }
    } else {
      if (idx > -1) {
        state.rfpChecked.splice(idx, 1) // .pop(data. publication_id)
      }
    }
  },
}

const getters = {
  hasAllRfpChecked: (state, getters) => {
    return getters.getRfpRequired.every((elem) =>
      state.rfpChecked.includes(elem)
    )
  },

  getRfpChecked: (state) => {
    return state.rfpChecked
  },

  hasRfpRequired: (state, getters) => {
    return getters.getRfpRequired.length
  },

  getRfpRequired: (state) => {
    return state.rfp
      .filter((doc) => {
        return doc.flag_required == 1
      })
      .map((_doc) => _doc.publication_id)
  },

  hasRfpLinks: (state, getters) => {
    return getters.getRfpLinks.length
  },

  getRfpLinks: (state) => {
    return state.rfp.filter((doc) => {
      return doc.modus == 'LINK'
    })
  },
  getRfpTexts: (state) => {
    return state.rfp.filter((doc) => {
      return doc.modus == 'TEXT'
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
