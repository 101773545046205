import axios from 'axios'
import store from '@/store'
import router from '@/router'
import NProgress from 'nprogress'

const domainsMap = new Map()

// Devel

domainsMap.set('adm.ftm-local.com.br:8582', {
  api: 'http://api.ftm-local.com.br',
  static: 'http://static.futurtekmed.com.br/',
})

// Prod

domainsMap.set('adm.futurtekmed.com.br', {
  api: 'https://api.futurtekmed.com.br',
  static: 'https://static.futurtekmed.com.br/',
})
domainsMap.set('adm.futurtekmed.com', {
  api: 'https://api.futurtekmed.com',
  static: 'https://static.futurtekmed.com/',
})

const getDomainObj = function () {
  return domainsMap.get(window.location.host)
}

const getStaticUrl = function () {
  let domain = getDomainObj()
  if (domain) return domain.static
}

const getApiUrl = function () {
  let domain = getDomainObj()
  if (domain) return domain.api
}

const axiosDefault = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const getAxiosInstance = function () {
  return axiosDefault
}

const responseHandler = function (response) {
  // Add toasts
  if ('toasts' in response.data) {
    store.dispatch('toaster/addNotifications', response.data.toasts)
  }

  // Error Markers
  if ('error_markers' in response.data) {
    if ('fields' in response.data.error_markers)
      store.dispatch('toaster/addFields', response.data.error_markers.fields)
    if ('pages' in response.data.error_markers)
      store.dispatch('toaster/addPages', response.data.error_markers.pages)
  }

  // Pages
  if ('toasts' in response.data) {
    store.dispatch('toaster/addNotifications', response.data.toasts)
  }

  // Messages
  if ('message_sha1' in response.data) {
    store.dispatch('notifications/hashCompare', response.data.message_sha1)
  }

  // Add Paginator Data
  if ('paginator' in response.data) {
    store.dispatch('paginator/setPaginatorData', response.data.paginator)
  }

  // Checagem de sessão expirada
  if (!response.data.session.auth) {
    store.dispatch('session/endSession')
    // store.dispatch('toaster/addNotification', {
    //   type: 'error',
    //   message:
    //     'Sentimos muito mas sua sessão expirou. Faça login novamente para utilizar a plataforma! ;)',
    //   ttl: 5000,
    // })
  }
}

const errorsHandler = function (err) {
  store.dispatch('toaster/addNotification', {
    type: 'error',
    message: 'Infelizmente a API não respondeu corretamente!',
    ttl: 3000,
  })
  //router.push('/error/503')

  //console.log(err)

  // if (err.response) {
  //   // The request was made and the server responded with a status code
  //   // that falls out of the range of 2xx

  //   console.log(err.response.data)
  //   console.log(err.response.status)
  //   console.log(err.response.headers)
  // } else if (err.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js

  //   console.log(err.request)
  // } else {
  //   // Something happened in setting up the request that triggered an Error

  //   console.log('Error', err.message)
  // }
  // console.log(err.config)
}

const apiPostData = function (url, body) {
  NProgress.start()
  return getAxiosInstance()
    .post(url, body)
    .then((response) => {
      if (response) {
        responseHandler(response)
        return response.data.data
      }
      errorsHandler(err)
    })
    .catch((err) => {
      errorsHandler(err)
    })
    .finally(() => {
      NProgress.done()
    })
}

const apiGetData = function (url) {
  NProgress.start()
  return getAxiosInstance()
    .get(url)
    .then((response) => {
      if (response) {
        responseHandler(response)
        return response.data.data
      }
      errorsHandler(err)
    })
    .catch((err) => {
      errorsHandler(err)
    })
    .finally(() => {
      NProgress.done()
    })
}

const uploadFile = function (url, file, fields, onUploadProgress) {
  NProgress.start()
  let formData = new FormData()
  formData.append('file', file)

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key])
  })

  return getAxiosInstance()
    .post(url, formData, {
      onUploadProgress,
    })
    .then((response) => {
      if (response) {
        responseHandler(response)
        return response.data.data
      }
      errorsHandler(err)
    })
    .catch((err) => {
      errorsHandler(err)
    })
    .finally(() => {
      NProgress.done()
    })
}

export { apiPostData, apiGetData, uploadFile, getStaticUrl, getApiUrl }
