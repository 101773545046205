import { apiGetData } from '@/services/ApiService.js'

const state = () => ({
  publications: [],
  signed: [],
  lock: 0,
})

const actions = {
  loadPublications({ dispatch, commit }) {
    commit('LOCK_STATUS', 0)
    apiGetData('contents/private-publisher/list').then((response) => {
      commit('LOAD_PUBLICATIONS', response)

      if (response) {
        response.forEach(function (pub) {
          if (pub.flag_lockable == 1) {
            commit('LOCK_STATUS', 1)
          }

          if (pub.flag_toastable == 1) {
            dispatch(
              'toaster/addNotification',
              {
                type: 'warning',
                message: `O documento "${pub.title}" está pendente de assinatura! `,
                ttl: 1000000000,
              },
              { root: true }
            )
          }
        })
      }
    })
  },

  loadSigned({ dispatch, commit }) {
    commit('LOCK_STATUS', 0)
    apiGetData('contents/private-publisher/list/signed').then((response) => {
      commit('LOAD_SIGNED', response)
    })
  },
}

const mutations = {
  LOAD_PUBLICATIONS(state, data) {
    state.publications = data ?? []
  },

  LOAD_SIGNED(state, data) {
    state.signed = data ?? []
  },

  LOCK_STATUS(state, status) {
    state.lock = status
  },
}

const getters = {
  getPublications: (state) => {
    return state.publications
  },
  hasPublications: (state) => {
    return state.publications.length > 0
  },
  getSigned: (state) => {
    return state.signed
  },
  hasSigned: (state) => {
    return state.signed.length > 0
  },
  getLockState: (state) => {
    return state.lock
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
